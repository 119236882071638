/*
.CoverImage {
  width: 100vw;
  height: 100vh;
}
*/

.CoverImage > img {
  object-fit: cover;
}


/*
@media (min-width: 500px) {
  .CoverImage {
    width: 500px;
    height: 500px;
  }
}
*/
