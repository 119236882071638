/* progress bar */
.Container {
  margin-top: 15px;
  height: 2px;
  left: 10%;
  width: 80%;
  background-color: lightgrey;
  position: relative;
  border-radius: 3px;
}
.Bar {
  height: 100%;
  background-color: #606060;
  border-radius: 3px;
  position: relative;
}

.Handle {
  background: transparent;
  border: transparent;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  padding: 00px;
  float: left;
  padding: -25px;
  margin-top: -30px;
  margin-left: -30px;
  position: absolute;
}

.HandleVisual {
  background: darkgray;
  border: 1px solid #999;
  border-radius: 5px;
  width: 6px;
  height: 6px;
  padding: 0px;
  margin-top: 26px;
  margin-left: 25px;
  position: relative;
}

.CurrentTime {
  float: left;
  margin-left: 10%;
  font-size: 0.8em;
  margin-top: 5px;
}

.TimeRemaining {
  float: right;
  margin-right: 10%;  
  font-size: 0.8em;
  margin-top: 5px;
}