
.body {
  margin-top: 0px;
  padding: 0px;
}

.Container {
  
}

@media (min-width: 30em) and (max-width: 50em) {
  .your-class {
  }
}

.Drawer {
  padding: 1rem;
  background-color: white;
  height: 50px;
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  transition: height 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

.Drawer.is-open {
  position: fixed;
  height: 250px;
  background-color: tomato;
}

.Controls {
  background-color: transparent;
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}