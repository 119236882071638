.c {
  background-color: black;
  color: lightgrey;
  font-size: x-large;
  right: 0;
  left: 0;
  width: 100vw;
  height: 500px;

  padding: 0;
  align: left;
}

#playlist div {
  color: lightgrey;
  align: left;
  width: 100%;
}

.Active {  
  color: #39ff14
}

.PlaylistContainer {
  background-color: darksalmon;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}