.Drawer {
  padding: 1rem;
  background-color: white;
  height: 50px;
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  transition: height 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

.Drawer.is-open {
  position: fixed;
  height: 250px;
  background-color: tomato;
}
