/*
.CircleImage {
  width: 100vw;
  height: 100vh;
}
*/

.CircleImage > img {
  object-fit: cover;
}


/*
@media (min-width: 500px) {
  .CircleImage {
    width: 500px;
    height: 500px;
  }
}
*/
