.PlaylistContainer {
  list-style: none;
  background-color: black;
  color: lightgrey;
  font-size: x-large;
  margin: auto;
  width: 100%;

  padding: 0;
  align: left;
}

#playlist div {
  cursor: pointer;
  display: inline-block;
  color: lightgrey;
  align: left;
  width: 100%;
}

.Active {
  background: #666;
}